<template>
  <div>
    <v-list three-line>
      <transition-group name="fade" tag="div">
        <kurcc-site-list-item v-for="(site, index) in sites" :key="index" :site="site">
          <v-col v-if="index !== sites.length -1" class="pa-0" cols="12">
            <v-divider/>
          </v-col>
        </kurcc-site-list-item>
      </transition-group>
    </v-list>
    <kurcc-infinite-loading :items.sync="sites" action-name="getAllSites"/>
  </div>
</template>

<script>
export default {
  name: 'KurccSiteList',
  components: {
    KurccSiteListItem: () => import('@/modules/more/components/KurccSiteListItem'),
    KurccInfiniteLoading: () => import('@/modules/app/components/KurccInfiniteLoading')
  },
  data () {
    return {
      sites: []
    }
  }
}
</script>
